.ag-header-cell {
    border-right: 1px solid black;
  }

  .my-header-class {
    font-weight: bold;
    background-color: #f5f5f5;
    color: 'green';
  }

  .ag-cell-red {
    background-color: #ff8080;
  }
  .ag-cell-green {
    background-color: #b3ffb3;
  }

  .my-header-class_paddock{
    text-align: center;
  }
 
  .my-header-class {
    text-align: center;
  }

  .ag-header-cell-label {
    justify-content: center;
  }

  /* .ag-theme-alpine .ag-header-group-cell-label {
    text-align: center;
    margin-left: 55px;
    font-weight: bold;
    font-size: 12px;
} */

.ag-theme-alpine .ag-header-cell-label{
  font-size: 10px;
  padding-left:10px !important;
}

.ag-header-cell,
.ag-header-group-cell {
  padding-right: 0;
  padding-left: 0;
}


.ag-header-group-cell.ag-header-group-cell-with-group  {
  border: 1px solid black; /* Replace #000000 with your desired border color */
  padding-left: 20px;
  background-color: violet;

}
.third-grid {
  height: 700px !important;
  
}
.third-grid .ag-header{
  background-color: #B08D57 !important;
}

.first-grid .ag-header{
  background-color: #F9F295 !important; 
}

.second-grid .ag-header{
  background-color: #BBC2CC !important;
}

.ag-header-group-cell .ag-header-group-cell-with-group .ag-header-group-cell-label .ag-header-group-text{
  text-align: center;
}

.my-spinner {
  width: 10px;
  position: ;
  top: 10px;
  right: 0px;
}

.my-renderer {
  /* margin-left: 20px; */
}


.highlighted-cell {
  background-color: yellow;
}

.ag-theme-alpine .ag-cell {
  font-size: 12px; /* Adjust the font size as per your requirement */
  font-weight:bold;
   /* height: 20px; */
}

.ag-cell {
  padding:0 !important; /* Set the desired row height */
}

.cell-span {
  background-color: #00e5ff;
}

.custom-border-class {
  border: 1px solid black; /* Example border style */
}

.horse-color{
  background-color: #00e5ff;
}

.two-line-header {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Center text within each line */
  font-weight:bold;
  
}
.first-line {
  font-size: 8px; /* Adjust the font size as needed */
}

.second-line {
  font-size: 8px; /* Adjust the font size as needed */
}

/* Custom cell renderer CSS */
.image-cell {
  position: relative;
}

.image-cell__background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* opacity: 0.5; */
}

.image-cell__number {
  position: absolute;
  top: 40%;
  left: 60%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.custom-header-style {
  font-weight: bold;  
  font-size: 10em; 
  font-family: tahoma;
}

.MuiButton-contained {
  border-radius: 50px !important;
}

/* Custom cell renderer CSS */
.image-cell-score {
  position: relative;
}

.image-cell__background-score {
  width: 70%;
  height: 70%;
  object-fit: cover;
  /* opacity: 0.5; */
}

.image-cell__number-score {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: blue;
}

.alerts-border {
  border-radius: 50px;
  display: inline-block;
  border: 5px green solid;
  animation: blink 3s;
  animation-iteration-count: 3;
}

@keyframes blink {
  0% {
      border-color: green;
  }
  50% {
      border-color: #fff;
  }
  100% {
      border-color: green;
  }
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

.custom-tooltip {
  width: 150px;
  padding: 5px;
  color: var(--ag-foreground-color);
  background-color: #5577cc;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

/* Add these styles to your CSS file or style solution */

/* Style for cells with parentheses */
.cell-with-parentheses {
  color: red; /* Set the text color to red for cells with parentheses */
}

/* Style for cells without parentheses */
.cell-without-parentheses {
  color: green; /* Set the text color to green for cells without parentheses */
}
